export const Box3D = ({ size }) => (
  <svg
    width={size ?? "40px"}
    height={size ?? "40px"}
    viewBox="0 0 65 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.8797 53.0249L25.3301 68.924L37.129 43.7459L64.6841 28.0942L56.8797 53.0249Z"
      fill="url(#paint0_linear_645_453)"
    />
    <path
      d="M0.814453 39.239L11.9956 16.9114L36.8699 44.6738L25.3375 68.9316L0.814453 39.239Z"
      fill="url(#paint1_linear_645_453)"
    />
    <path
      d="M40.8471 0.665039L11.9492 16.9695L36.7374 44.5412L64.7067 28.1042L40.8471 0.665039Z"
      fill="url(#paint2_linear_645_453)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_645_453"
        x1="45.0071"
        y1="28.0942"
        x2="45.0071"
        y2="68.924"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0872193" stopColor="#BCFF92" />
        <stop offset="1" stopColor="#5A9D2F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_645_453"
        x1="18.8422"
        y1="16.9114"
        x2="18.8422"
        y2="68.9316"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38CC9E" />
        <stop offset="1" stopColor="#097A57" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_645_453"
        x1="38.328"
        y1="0.665039"
        x2="38.328"
        y2="44.5412"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A9D2F" />
        <stop offset="1" stopColor="#A9FF73" />
      </linearGradient>
    </defs>
  </svg>
);

export const Triangle3D = () => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.86">
      <path
        d="M47.0985 23.6831L38.1264 25.1406L35.9491 60.2853L44.8672 58.9105L47.0985 23.6831Z"
        fill="url(#paint0_linear_648_2)"
      />
      <path
        d="M13.2294 37.0825L4.25729 38.54L39.5757 69.7295L48.5478 68.272L13.2294 37.0825Z"
        fill="url(#paint1_linear_648_2)"
      />
      <path
        d="M51.6989 15.0082L42.7268 16.4657L4.25722 38.5401L13.2293 37.0826L51.6989 15.0082Z"
        fill="url(#paint2_linear_648_2)"
      />
      <path
        d="M21.6249 38.316L44.8673 58.911L44.9213 58.8283L48.5478 68.2724L13.2293 37.0829L21.6249 38.316Z"
        fill="url(#paint3_linear_648_2)"
      />
      <path
        d="M48.5484 68.2727L44.9219 58.8286L47.0992 23.6839L46.9626 23.7127L51.6455 15.0916L48.5484 68.2727Z"
        fill="url(#paint4_linear_648_2)"
      />
      <path
        d="M51.645 15.0912L46.962 23.7123L21.625 38.3159L13.2294 37.0828L51.699 15.0085L51.645 15.0912Z"
        fill="url(#paint5_linear_648_2)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_648_2"
        x1="40.2553"
        y1="58.6204"
        x2="40.2553"
        y2="24.4196"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#108661" />
        <stop offset="1" stopColor="#34C598" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_648_2"
        x1="36.0929"
        y1="61.3259"
        x2="12.3021"
        y2="36.2526"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61A635" />
        <stop offset="1" stopColor="#A2F66D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_648_2"
        x1="5.82985"
        y1="39.9053"
        x2="72.5491"
        y2="0.370533"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38CC9E" />
        <stop offset="1" stopColor="#68AF3B" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_648_2"
        x1="47.4483"
        y1="63.6825"
        x2="17.6313"
        y2="38.6364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#73BF43" />
        <stop offset="1" stopColor="#99FFDF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_648_2"
        x1="46.9625"
        y1="59.1943"
        x2="48.965"
        y2="26.0384"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38CC9E" />
        <stop offset="1" stopColor="#8EE15A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_648_2"
        x1="21.9361"
        y1="35.3792"
        x2="49.3558"
        y2="18.7243"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8EE15A" />
        <stop offset="1" stopColor="#D2FFF1" />
      </linearGradient>
    </defs>
  </svg>
);

export const Circle = () => (
  <svg
    width="51"
    height="52"
    viewBox="0 0 51 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="25.1912"
      cy="26.1219"
      r="23.1902"
      stroke="#BCFF92"
      strokeWidth="4"
    />
  </svg>
);

export const DotIcon = ({ size }) => (
  <svg
    width="66"
    height="63"
    viewBox="0 0 66 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4021 1.97284C21.4021 1.22775 20.7742 0.597298 20.0321 0.597298C19.29 0.597298 18.6621 1.22775 18.6621 1.97284C18.6621 2.71793 19.29 3.34839 20.0321 3.34839C20.7742 3.34839 21.4021 2.77524 21.4021 1.97284Z"
      fill="#005138"
    />
    <path
      d="M30.2497 1.97284C30.2497 1.22775 29.6218 0.597298 28.8798 0.597298C28.1377 0.597298 27.5098 1.22775 27.5098 1.97284C27.5098 2.71793 28.1377 3.34839 28.8798 3.34839C29.6218 3.34839 30.2497 2.77524 30.2497 1.97284Z"
      fill="#005138"
    />
    <path
      d="M39.0984 1.97284C39.0984 1.22775 38.4705 0.597298 37.7284 0.597298C36.9863 0.597298 36.3584 1.22775 36.3584 1.97284C36.3584 2.71793 36.9863 3.34839 37.7284 3.34839C38.4705 3.34839 39.0984 2.77524 39.0984 1.97284Z"
      fill="#005138"
    />
    <path
      d="M47.946 1.97284C47.946 1.22775 47.3181 0.597298 46.576 0.597298C45.834 0.597298 45.2061 1.22775 45.2061 1.97284C45.2061 2.71793 45.834 3.34839 46.576 3.34839C47.3181 3.34839 47.946 2.77524 47.946 1.97284Z"
      fill="#005138"
    />
    <path
      d="M12.5554 10.3408C12.5554 9.59572 11.9275 8.96526 11.1854 8.96526C10.4433 8.96526 9.81543 9.59572 9.81543 10.3408C9.81543 11.0859 10.4433 11.7164 11.1854 11.7164C11.9846 11.7737 12.5554 11.1432 12.5554 10.3408Z"
      fill="#005138"
    />
    <path
      d="M21.4031 10.3408C21.4031 9.59572 20.7752 8.96526 20.0331 8.96526C19.291 8.96526 18.6631 9.59572 18.6631 10.3408C18.6631 11.0859 19.291 11.7164 20.0331 11.7164C20.7752 11.7737 21.4031 11.1432 21.4031 10.3408Z"
      fill="#005138"
    />
    <path
      d="M30.2517 10.3408C30.2517 9.59572 29.6238 8.96526 28.8817 8.96526C28.1396 8.96526 27.5117 9.59572 27.5117 10.3408C27.5117 11.0859 28.1396 11.7164 28.8817 11.7164C29.6238 11.7737 30.2517 11.1432 30.2517 10.3408Z"
      fill="#005138"
    />
    <path
      d="M39.0994 10.3408C39.0994 9.59572 38.4714 8.96526 37.7294 8.96526C36.9873 8.96526 36.3594 9.59572 36.3594 10.3408C36.3594 11.0859 36.9873 11.7164 37.7294 11.7164C38.4714 11.7737 39.0994 11.1432 39.0994 10.3408Z"
      fill="#005138"
    />
    <path
      d="M47.947 10.3408C47.947 9.59572 47.3191 8.96526 46.577 8.96526C45.8349 8.96526 45.207 9.59572 45.207 10.3408C45.207 11.0859 45.8349 11.7164 46.577 11.7164C47.3191 11.7737 47.947 11.1432 47.947 10.3408Z"
      fill="#005138"
    />
    <path
      d="M56.7947 10.3408C56.7947 9.59572 56.1668 8.96526 55.4247 8.96526C54.6826 8.96526 54.0547 9.59572 54.0547 10.3408C54.0547 11.0859 54.6826 11.7164 55.4247 11.7164C56.1668 11.7737 56.7947 11.1432 56.7947 10.3408Z"
      fill="#005138"
    />
    <path
      d="M12.5554 18.7663C12.5554 18.0212 11.9275 17.3908 11.1854 17.3908C10.4433 17.3908 9.81543 18.0212 9.81543 18.7663C9.81543 19.5114 10.4433 20.1418 11.1854 20.1418C11.9846 20.1418 12.5554 19.5114 12.5554 18.7663Z"
      fill="#005138"
    />
    <path
      d="M21.4031 18.7663C21.4031 18.0212 20.7752 17.3908 20.0331 17.3908C19.291 17.3908 18.6631 18.0212 18.6631 18.7663C18.6631 19.5114 19.291 20.1418 20.0331 20.1418C20.7752 20.1418 21.4031 19.5114 21.4031 18.7663Z"
      fill="#005138"
    />
    <path
      d="M30.2517 18.7663C30.2517 18.0212 29.6238 17.3908 28.8817 17.3908C28.1396 17.3908 27.5117 18.0212 27.5117 18.7663C27.5117 19.5114 28.1396 20.1418 28.8817 20.1418C29.6238 20.1418 30.2517 19.5114 30.2517 18.7663Z"
      fill="#005138"
    />
    <path
      d="M39.0994 18.7663C39.0994 18.0212 38.4714 17.3908 37.7294 17.3908C36.9873 17.3908 36.3594 18.0212 36.3594 18.7663C36.3594 19.5114 36.9873 20.1418 37.7294 20.1418C38.4714 20.1418 39.0994 19.5114 39.0994 18.7663Z"
      fill="#005138"
    />
    <path
      d="M47.947 18.7663C47.947 18.0212 47.3191 17.3908 46.577 17.3908C45.8349 17.3908 45.207 18.0212 45.207 18.7663C45.207 19.5114 45.8349 20.1418 46.577 20.1418C47.3191 20.1418 47.947 19.5114 47.947 18.7663Z"
      fill="#005138"
    />
    <path
      d="M56.7947 18.7663C56.7947 18.0212 56.1668 17.3908 55.4247 17.3908C54.6255 17.3334 54.0547 17.9639 54.0547 18.7663C54.0547 19.5114 54.6826 20.1418 55.4247 20.1418C56.1668 20.1418 56.7947 19.5114 56.7947 18.7663Z"
      fill="#005138"
    />
    <path
      d="M3.70677 27.134C3.70677 26.3889 3.07886 25.7584 2.33678 25.7584C1.59471 25.7584 0.966797 26.3889 0.966797 27.134C0.966797 27.8791 1.59471 28.5095 2.33678 28.5095C3.13594 28.5095 3.70677 27.8791 3.70677 27.134Z"
      fill="#005138"
    />
    <path
      d="M12.5544 27.134C12.5544 26.3889 11.9265 25.7584 11.1844 25.7584C10.4424 25.7584 9.81445 26.3889 9.81445 27.134C9.81445 27.8791 10.4424 28.5095 11.1844 28.5095C11.9836 28.5095 12.5544 27.8791 12.5544 27.134Z"
      fill="#005138"
    />
    <path
      d="M21.4031 27.134C21.4031 26.3889 20.7752 25.7584 20.0331 25.7584C19.291 25.7584 18.6631 26.3889 18.6631 27.134C18.6631 27.8791 19.291 28.5095 20.0331 28.5095C20.7752 28.5095 21.4031 27.8791 21.4031 27.134Z"
      fill="#005138"
    />
    <path
      d="M30.2507 27.134C30.2507 26.3889 29.6228 25.7584 28.8807 25.7584C28.1387 25.7584 27.5107 26.3889 27.5107 27.134C27.5107 27.8791 28.1387 28.5095 28.8807 28.5095C29.6228 28.5095 30.2507 27.8791 30.2507 27.134Z"
      fill="#005138"
    />
    <path
      d="M39.0984 27.134C39.0984 26.3889 38.4705 25.7584 37.7284 25.7584C36.9863 25.7584 36.3584 26.3889 36.3584 27.134C36.3584 27.8791 36.9863 28.5095 37.7284 28.5095C38.4705 28.5095 39.0984 27.8791 39.0984 27.134Z"
      fill="#005138"
    />
    <path
      d="M47.946 27.134C47.946 26.3889 47.3181 25.7584 46.576 25.7584C45.834 25.7584 45.2061 26.3889 45.2061 27.134C45.2061 27.8791 45.834 28.5095 46.576 28.5095C47.3181 28.5095 47.946 27.8791 47.946 27.134Z"
      fill="#005138"
    />
    <path
      d="M56.7937 27.134C56.7937 26.3889 56.1658 25.7584 55.4237 25.7584C54.6816 25.7584 54.0537 26.3889 54.0537 27.134C54.0537 27.8791 54.6816 28.5095 55.4237 28.5095C56.1658 28.5095 56.7937 27.8791 56.7937 27.134Z"
      fill="#005138"
    />
    <path
      d="M64.2723 25.7584C65.029 25.7584 65.6423 26.3743 65.6423 27.134C65.6423 27.8937 65.029 28.5095 64.2723 28.5095C63.5157 28.5095 62.9023 27.8937 62.9023 27.134C62.9023 26.3743 63.5157 25.7584 64.2723 25.7584Z"
      fill="#005138"
    />
    <path
      d="M3.70677 35.5019C3.70677 34.7568 3.07886 34.1263 2.33678 34.1263C1.59471 34.1263 0.966797 34.7568 0.966797 35.5019C0.966797 36.247 1.59471 36.8774 2.33678 36.8774C3.13594 36.8774 3.70677 36.247 3.70677 35.5019Z"
      fill="#005138"
    />
    <path
      d="M12.5544 35.5019C12.5544 34.7568 11.9265 34.1263 11.1844 34.1263C10.4424 34.1263 9.81445 34.7568 9.81445 35.5019C9.81445 36.247 10.4424 36.8774 11.1844 36.8774C11.9836 36.8774 12.5544 36.247 12.5544 35.5019Z"
      fill="#005138"
    />
    <path
      d="M21.4031 35.5019C21.4031 34.7568 20.7752 34.1263 20.0331 34.1263C19.291 34.1263 18.6631 34.7568 18.6631 35.5019C18.6631 36.247 19.291 36.8774 20.0331 36.8774C20.7752 36.8774 21.4031 36.247 21.4031 35.5019Z"
      fill="#005138"
    />
    <path
      d="M30.2507 35.5019C30.2507 34.7568 29.6228 34.1263 28.8807 34.1263C28.1387 34.1263 27.5107 34.7568 27.5107 35.5019C27.5107 36.247 28.1387 36.8774 28.8807 36.8774C29.6228 36.8774 30.2507 36.247 30.2507 35.5019Z"
      fill="#005138"
    />
    <path
      d="M39.0984 35.5019C39.0984 34.7568 38.4705 34.1263 37.7284 34.1263C36.9863 34.1263 36.3584 34.7568 36.3584 35.5019C36.3584 36.247 36.9863 36.8774 37.7284 36.8774C38.4705 36.8774 39.0984 36.247 39.0984 35.5019Z"
      fill="#005138"
    />
    <path
      d="M47.946 35.5019C47.946 34.7568 47.3181 34.1263 46.576 34.1263C45.834 34.1263 45.2061 34.7568 45.2061 35.5019C45.2061 36.247 45.834 36.8774 46.576 36.8774C47.3181 36.8774 47.946 36.247 47.946 35.5019Z"
      fill="#005138"
    />
    <path
      d="M56.7937 35.5019C56.7937 34.7568 56.1658 34.1263 55.4237 34.1263C54.6816 34.1263 54.0537 34.7568 54.0537 35.5019C54.0537 36.247 54.6816 36.8774 55.4237 36.8774C56.1658 36.8774 56.7937 36.247 56.7937 35.5019Z"
      fill="#005138"
    />
    <path
      d="M65.6423 35.5019C65.6423 34.7568 65.0144 34.1263 64.2723 34.1263C63.5303 34.1263 62.9023 34.7568 62.9023 35.5019C62.9023 36.247 63.5303 36.8774 64.2723 36.8774C65.0144 36.8774 65.6423 36.247 65.6423 35.5019Z"
      fill="#005138"
    />
    <path
      d="M12.5554 43.8699C12.5554 43.1248 11.9275 42.4943 11.1854 42.4943C10.4433 42.4943 9.81543 43.1248 9.81543 43.8699C9.81543 44.6149 10.4433 45.2454 11.1854 45.2454C11.9846 45.3027 12.5554 44.6723 12.5554 43.8699Z"
      fill="#005138"
    />
    <path
      d="M21.4031 43.8699C21.4031 43.1248 20.7752 42.4943 20.0331 42.4943C19.291 42.4943 18.6631 43.1248 18.6631 43.8699C18.6631 44.6149 19.291 45.2454 20.0331 45.2454C20.7752 45.3027 21.4031 44.6723 21.4031 43.8699Z"
      fill="#005138"
    />
    <path
      d="M30.2517 43.8699C30.2517 43.1248 29.6238 42.4943 28.8817 42.4943C28.1396 42.4943 27.5117 43.1248 27.5117 43.8699C27.5117 44.6149 28.1396 45.2454 28.8817 45.2454C29.6238 45.3027 30.2517 44.6723 30.2517 43.8699Z"
      fill="#005138"
    />
    <path
      d="M39.0994 43.8699C39.0994 43.1248 38.4714 42.4943 37.7294 42.4943C36.9873 42.4943 36.3594 43.1248 36.3594 43.8699C36.3594 44.6149 36.9873 45.2454 37.7294 45.2454C38.4714 45.3027 39.0994 44.6723 39.0994 43.8699Z"
      fill="#005138"
    />
    <path
      d="M47.947 43.8699C47.947 43.1248 47.3191 42.4943 46.577 42.4943C45.8349 42.4943 45.207 43.1248 45.207 43.8699C45.207 44.6149 45.8349 45.2454 46.577 45.2454C47.3191 45.3027 47.947 44.6723 47.947 43.8699Z"
      fill="#005138"
    />
    <path
      d="M56.7947 43.8699C56.7947 43.1248 56.1668 42.4943 55.4247 42.4943C54.6255 42.4943 54.0547 43.1248 54.0547 43.8699C54.0547 44.6149 54.6826 45.2454 55.4247 45.2454C56.1668 45.3027 56.7947 44.6723 56.7947 43.8699Z"
      fill="#005138"
    />
    <path
      d="M12.5554 52.2954C12.5554 51.5503 11.9275 50.9198 11.1854 50.9198C10.4433 50.9198 9.81543 51.5503 9.81543 52.2954C9.81543 53.0404 10.4433 53.6709 11.1854 53.6709C11.9846 53.6709 12.5554 53.0404 12.5554 52.2954Z"
      fill="#005138"
    />
    <path
      d="M21.4031 52.2954C21.4031 51.5503 20.7752 50.9198 20.0331 50.9198C19.291 50.9198 18.6631 51.5503 18.6631 52.2954C18.6631 53.0404 19.291 53.6709 20.0331 53.6709C20.7752 53.6709 21.4031 53.0404 21.4031 52.2954Z"
      fill="#005138"
    />
    <path
      d="M30.2517 52.2954C30.2517 51.5503 29.6238 50.9198 28.8817 50.9198C28.1396 50.9198 27.5117 51.5503 27.5117 52.2954C27.5117 53.0404 28.1396 53.6709 28.8817 53.6709C29.6238 53.6709 30.2517 53.0404 30.2517 52.2954Z"
      fill="#005138"
    />
    <path
      d="M39.0994 52.2954C39.0994 51.5503 38.4714 50.9198 37.7294 50.9198C36.9873 50.9198 36.3594 51.5503 36.3594 52.2954C36.3594 53.0404 36.9873 53.6709 37.7294 53.6709C38.4714 53.6709 39.0994 53.0404 39.0994 52.2954Z"
      fill="#005138"
    />
    <path
      d="M47.947 52.2954C47.947 51.5503 47.3191 50.9198 46.577 50.9198C45.8349 50.9198 45.207 51.5503 45.207 52.2954C45.207 53.0404 45.8349 53.6709 46.577 53.6709C47.3191 53.6709 47.947 53.0404 47.947 52.2954Z"
      fill="#005138"
    />
    <path
      d="M56.7947 52.2954C56.7947 51.5503 56.1668 50.9198 55.4247 50.9198C54.6255 50.8625 54.0547 51.4929 54.0547 52.2954C54.0547 53.0404 54.6826 53.6709 55.4247 53.6709C56.1668 53.6709 56.7947 53.0404 56.7947 52.2954Z"
      fill="#005138"
    />
    <path
      d="M21.4021 60.663C21.4021 59.9179 20.7742 59.2875 20.0321 59.2875C19.29 59.2875 18.6621 59.9179 18.6621 60.663C18.6621 61.4081 19.29 62.0386 20.0321 62.0386C20.7742 62.0386 21.4021 61.4081 21.4021 60.663Z"
      fill="#005138"
    />
    <path
      d="M30.2497 60.663C30.2497 59.9179 29.6218 59.2875 28.8798 59.2875C28.1377 59.2875 27.5098 59.9179 27.5098 60.663C27.5098 61.4081 28.1377 62.0386 28.8798 62.0386C29.6218 62.0386 30.2497 61.4081 30.2497 60.663Z"
      fill="#005138"
    />
    <path
      d="M39.0984 60.663C39.0984 59.9179 38.4705 59.2875 37.7284 59.2875C36.9863 59.2875 36.3584 59.9179 36.3584 60.663C36.3584 61.4081 36.9863 62.0386 37.7284 62.0386C38.4705 62.0386 39.0984 61.4081 39.0984 60.663Z"
      fill="#005138"
    />
    <path
      d="M47.946 60.663C47.946 59.9179 47.3181 59.2875 46.576 59.2875C45.834 59.2875 45.2061 59.9179 45.2061 60.663C45.2061 61.4081 45.7769 62.0386 46.576 62.0386C47.3181 62.0386 47.946 61.4081 47.946 60.663Z"
      fill="#005138"
    />
  </svg>
);
